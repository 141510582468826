/* Light Theme */
:root {
  --primary: #003F7D;
  --lightprimary: #003F7D;
  --primary2: #F6921E;
  --secondary: #4D4D4D;
  --secondary2: #889298;
  --lightsecondary: #767E94;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #F5F7FA;
  --dark: #343a40;
  --white: #fff;
}

/* Dark Theme */
[data-theme="dark"] {
  --primary: #17a2b8;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #007bff;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #343a40;
  --dark: #e0e1e2;
}