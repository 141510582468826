@import url("./fonts.css");
@import url("./color.css");

*:focus {
  outline: none !important;
  box-shadow: none !important;
  /* border: none !important; */
}

.popins_regular {
  font-family: "popins_regular";
}

.popins_bold {
  font-family: "popins_bold";
}

.popins_medium {
  font-family: "popins_medium";
}

.popins_semibold {
  font-family: "popins_semibold";
}

.popins_light {
  font-family: "popins_light";
}

.inter_regular {
  font-family: "inter_regular";
}

.inter_bold {
  font-family: "inter_bold";
}

.inter_medium {
  font-family: "inter_medium";
}

.inter_semibold {
  font-family: "inter_semibold";
}

.inter_light {
  font-family: "inter_light";
}

* {
  font-family: "inter_regular";
}

/* font-families classes of plusJakara */

.plusJakara_regular {
  font-family: "plusJakara_regular";
}

.plusJakara_bold {
  font-family: "plusJakara_bold";
}

.plusJakara_medium {
  font-family: "plusJakara_medium";
}

.plusJakara_semibold {
  font-family: "plusJakara_semibold";
}

.plusJakara_light {
  font-family: "plusJakara_light";
}

/* text colors classes */
.text_primary {
  color: var(--primary);
}
.text_primary2 {
  color: var(--primary2);
}

.text_secondary {
  color: var(--secondary);
}
.text_secondary2 {
  color: var(--secondary2);
}
.text_info {
  color: var(--info);
}

.text_white {
  color: var(--white);
}

.text_lightprimary {
  color: var(--lightprimary);
}

.text_lightsecondary {
  color: var(--lightsecondary);
}
.text_light {
  color: var(--light);
}

.text_black {
  color: var(--black);
}

.text_dark {
  color: var(--dark);
}

.phon_inp input[type="tel"] {
  border-radius: 8px;
  /* border: 1px solid #d4d4d4 !important; */
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  height: 50px !important;
  font-family: "plusJakara_medium" !important;
  width: 100%;
  font-size: 16px;
  max-width: 100%;
  -webkit-appearance: none;
}

.react-tel-input .flag-dropdown {
  /* border: .25px solid #003F7D !important; */
  border: none !important;
  background-color: transparent !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.react-tel-input .selected-flag {
  border: 1px solid #d4d4d4 !important;
  width: 40px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  /* border: none !important; */
}

.phon_inp input[type="tel"]:focus {
  font-size: 16px;
  max-width: 100%;
  -webkit-appearance: none;
  user-scalable: no;
  touch-action: manipulation;
}

/* background colors classes */
.bg_primary {
  background-color: var(--primary);
}
.bg_primary2 {
  background-color: var(--primary2);
}
.bg_info {
  background-color: var(--info);
}
.bg_primary2 {
  background-color: var(--primary2);
}

.bg_lightprimary {
  background-color: var(--lightprimary);
}
.bg_light {
  background-color: var(--light);
}

.bg_secondary {
  background-color: var(--secondary);
}
.bg_secondary2 {
  background-color: var(--secondary2);
}

.bg_white {
  background-color: var(--white);
}

.bg_black {
  background-color: var(--black);
}

.bg_dark {
  background-color: var(--dark);
}
.bg_lightsecondary {
  background-color: var(--lightsecondary);
}

:where(.css-dev-only-do-not-override-mzwlov).ant-list * {
  min-height: auto !important;
}

.cleaning_header {
  padding-top: 3rem;
  background-image: url("../assets/png/image\ 42.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 40rem;
}

.home_header_width {
  width: 100%;
}
@media (min-width: 991px) {
  .home_header_width {
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .home_header_width {
    width: 95%;
  }
}
@media (min-width: 1580px) {
  .home_header_width {
    width: 1450px;
  }
}

.cleaning_header2 {
  padding-top: 6rem;
  min-height: 43rem;
}

@media (max-width: 991px) {
  .cleaning_header {
    min-height: 37rem;
  }
  .cleaning_header2 {
    min-height: 37rem;
  }
}

@media (max-width: 768px) {
  .cleaning_header {
    min-height: 24rem;
  }
  .cleaning_header2 {
    padding-top: 4rem;
    min-height: 24rem;
  }
}

.gridWork1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 1100px) {
  .gridWork1 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .gridWork1 {
    grid-template-columns: 1fr;
  }
}

.grid2 {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.grid2 .gridimg {
  max-width: 40rem;
  margin: auto !important;
}

@media (max-width: 1300px) {
  .grid2 .gridimg {
    max-width: 30rem;
  }
}
@media (max-width: 991px) {
  .grid2 {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .grid2 .gridimg {
    max-width: 25rem;
  }
}

@media (max-width: 768px) {
  .grid2 {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .grid2 .gridimg {
    max-width: 20rem;
  }
}

.grid3 {
  width: 100%;
  display: grid;
  justify-content: space-between;
  align-items: start;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 1100px) {
  .grid3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .grid3 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .grid3 {
    grid-template-columns: 1fr;
  }
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  /* color: #FFFFFF !important; */
}

.main_nav {
  flex: none;
  height: auto;
  left: 0;
  backdrop-filter: blur(15px);
  background-color: var(--white);
  position: fixed;
  right: 0;
  top: 0;
  transform: perspective(1200px);
  opacity: 1;
  z-index: 999px;
  transition: background-color 0.3s ease, border-bottom 0.3s ease,
    transform 0.3s ease;
}

.main_nav.scrolled {
  background-color: var(--light);
  z-index: 999px;
  border-bottom: 1px solid #535353;
  transform: perspective(1200px) translateX(0px) translateY(-82px) scale(1)
    rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0px);
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input-wrapper .input-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
}

.form-control::placeholder {
  color: #b4b4b4 !important;
  font-family: "plusJakara_regular" !important;
}
.form-control:focus {
  color: #333 !important;
}
.form-control {
  font-size: 14px !important;
  color: #333 !important;
}
.inputs-container {
  display: flex;
  gap: 16px; /* Adjust spacing between inputs if needed */
}
.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.input-wrapper input {
  width: 100%;
  font-size: 15px !important;
  padding-left: 40px;
}

.input-wrapper:nth-of-type(2) .form-control {
  border-left: 1.5px solid #dcdcdc !important;
}

.input-wrapper .form-control {
  border: none !important;
  border-radius: 0px !important;
}

.auth_form .form-control {
  border: 1px solid #d4d4d4 !important;
}

.styles_react-code-input__CRulA {
  display: flex !important;
  gap: 1rem !important;
  justify-content: center !important;
}
.styles_react-code-input__CRulA > input {
  border: 1px solid #c2c3c3 !important;
  border-radius: 20px !important;
  width: 80px !important;
  height: 80px !important;
  font-size: 24px !important;
  font-family: "plusJakara_medium" !important;
  background-color: transparent !important;
}

@media (max-width: 580px) {
  .styles_react-code-input__CRulA {
    gap: 1rem !important;
  }
  .styles_react-code-input__CRulA > input {
    width: 60px !important;
    height: 60px !important;
    font-size: 20px !important;
  }
}

.styles_react-code-input__CRulA > input:focus {
  background-color: #003f7d !important;
  font-family: "plusJakara_medium" !important;
  color: #ebeef7 !important;
}
.sub_grid_dashboard2:hover {
  background-color: #f4f4f4;
}

.auth_bg_img {
  position: relative;
  background-image: url("../assets/png/cleaning4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh !important;
}

.auth_bg_img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.bg-overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.bg-overlay3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.auth_bg_img > * {
  position: relative;
  z-index: 2;
}
.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 1200px) {
  .grid4 {
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 992px) {
  .grid4 {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 480px) {
  .grid4 {
    grid-template-columns: 1fr;
  }
}

.grid5 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* gap: 2rem; */
  gap: 1rem;
}

@media (max-width: 1200px) {
  .grid5 {
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 992px) {
  .grid5 {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 480px) {
  .grid5 {
    grid-template-columns: 1fr;
  }
}

.grid6 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

@media (max-width: 1200px) {
  .grid6 {
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 992px) {
  .grid6 {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 480px) {
  .grid6 {
    grid-template-columns: 1fr;
  }
}
.grid7 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

@media (max-width: 1200px) {
  .grid7 {
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .grid7 {
    grid-template-columns: 1fr;
  }
}
.grid8 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.gmnoprint {
  display: none !important;
}

.custom-phone-input {
  border: 0.25px solid #d4d4d4 !important;
  /* Add any other styles you need */
}

.form-check-input {
  border: 1px solid #b4b4b4 !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
}
.form-check-input:focus {
  border: 1px solid #b4b4b4 !important;
}

.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: transparent !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu::after {
  display: none !important;
}
:where(.css-dev-only-do-not-override-mzwlov).ant-menu-horizontal {
  border-bottom: none !important;
  line-height: 2 !important;
}

.hover_button {
  background-color: var(--primary2);
  color: var(--white);
  transition: all 0.2s linear;
}
.hover_button:hover {
  border: 1px solid var(--primary2);
  background-color: transparent;
  color: var(--primary2);
}
.img_hover {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transform-origin: center center; /* Ensures smooth scaling from the center */
}

.img_hover:hover {
  transform: scale(1.2); /* Adjust scale to 1.2 for a less drastic zoom */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow on hover */
}

.dODGfY {
  background-color: #f4f4f4 !important;
  /* border-radius: 8px !important; */
  min-height: 55px !important;
  overflow: hidden !important;
  font-family: "plusJakara_medium" !important;
}

.kdoubu {
  background-color: #f4f4f4 !important;
  /* border-radius: 8px !important; */
  min-height: 55px !important;
  overflow: hidden !important;
  font-family: "plusJakara_medium" !important;
}

.hSQKHD {
  min-height: 60px !important;
}

.jURlRn {
  font-size: 14px !important;
  font-family: "plusJakara_medium" !important;
}

.eGxbrW {
  font-size: 14px !important;
  font-family: "plusJakara_medium" !important;
}

.kzZBXj {
  font-size: 14px !important;
  font-family: "plusJakara_medium" !important;
}

.kzZBXj {
  font-size: 14px !important;
  font-family: "plusJakara_medium" !important;
}

.eFEHrh {
  font-size: 14px !important;
  font-family: "plusJakara_medium" !important;
}

.gridDashboard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* justify-content: start; */
  gap: 1rem;
}

@media (max-width: 1392px) {
  .gridDashboard {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .gridDashboard {
    grid-template-columns: 1fr;
  }
}

.gridDashboard2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  /* justify-content: start; */
  gap: 12px;
}

@media (max-width: 1392px) {
  .gridDashboard2 {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .gridDashboard2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .gridDashboard2 {
    grid-template-columns: 1fr;
  }
}

.gridDashboard3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 12px;
}

@media (max-width: 1392px) {
  .gridDashboard3 {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .gridDashboard3 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .gridDashboard3 {
    grid-template-columns: 1fr;
  }
}

.gridDashboard4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1392px) {
  .gridDashboard4 {
    grid-template-columns: 1fr;
  }
}

.gridDashboard5 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 1392px) {
  .gridDashboard5 {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.gridDashboard6 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 1200px) {
  .gridDashboard6 {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}
@media (max-width: 990px) {
  .gridDashboard6 {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

.apexcharts-toolbar {
  display: none !important;
}

/* side bar menu */
.css-vj11vy.ps-menu-root {
  padding: 0px !important;
}

.css-1t8x7v1 > .ps-menu-button:hover {
  background-color: transparent !important;
}

.css-dip3t8 {
  border-right: 1px solid #ccc !important;
}

.css-18unl23 {
  background-color: var(--light) !important;
}

.css-1tqrhto > .ps-menu-button:hover {
  background-color: transparent !important;
}

/* notify */

.media-list {
  max-height: 25rem;
  height: fit-content !important;
  overflow-y: auto !important;
}

.scrollable-container {
  position: relative;
}
.media-list .list-item {
  display: flex;
  flex-grow: 1;
  padding: 0.9rem 1.28rem;
  border: none;
  /* border-bottom: 1px solid #ccc;  */
}

.media-list .list-item:hover {
  background: #f8f9fa;
}

.media-list .list-item .media-meta {
  color: #b4b4b4;
}

.media-list a:last-of-type .list-item {
  border-bottom: none;
}

.dropdown-notification .list-item-body .media-heading {
  /* color: #b4b4b4;  */
  margin-bottom: 0;
  /* line-height: 1.2; */
}

.dropdown-menu-media {
  width: 30rem;
}

.dropdown-menu-media .dropdown-menu-header {
  border-bottom: 1px solid #ccc;
}

.dropdown-menu-media .media-list .list-item {
  display: flex;
  flex-grow: 1;
  padding: 1rem 1.5rem;
  border: none;
  border-bottom: 1px solid #ccc;
}

.dropdown-menu-media .media-list .list-item:hover {
  background: #f8f9fa;
}

.dropdown-menu-media .media-list .list-item .media-meta {
  color: #b4b4b4;
}

.dropdown-menu-media .media-list a:last-of-type .list-item {
  border-bottom: none;
}

.dropdown-menu-media .dropdown-menu-footer {
  border-top: 1px solid #ccc;
}

.badge-up {
  position: absolute;
  top: 0px;
  right: 5px !important;
  min-width: 1.2rem;
  min-height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
  line-height: 0.786;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

/* skeleton */

.custom-table-skeleton {
  width: 100%;
  min-height: 300px; /* or any desired height */
}

.skeleton-header,
.skeleton-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.skeleton-row {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
}

.skeleton-box {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.skeleton-box .ant-skeleton-input {
  width: 100%;
}

.skeleton-loader {
  background-color: #f0f0f0;
  width: 100%;
  border-radius: 10px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}

.skeleton-box {
  background: #f0f0f0;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-img {
  background: #e0e0e0;
}

.skeleton-text {
  background: #e0e0e0;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.ps__rail-y {
  height: auto !important;
}
.ps__thumb-y {
  height: auto !important;
}

/* react select */

/* Height and general styles */
.css-t3ipsp-control,
.css-13cymwt-control {
  min-height: 50px !important;
}

/* Remove focus outline and shadow */
.css-t3ipsp-control:focus,
.css-t3ipsp-control:active,
.css-13cymwt-control:focus,
.css-13cymwt-control:active,
.css-t3ipsp-control:focus-visible,
.css-13cymwt-control:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  border-color: #b4b4b4 !important;
}

/* Hover effect */
.css-t3ipsp-control:hover,
.css-13cymwt-control:hover {
  border-color: #b4b4b4 !important;
  box-shadow: none !important;
  outline: none !important;
}

/* Custom font for select placeholders */
.css-1jqq78o-placeholder,
.css-19bb58m,
.css-1cfo1cf {
  font-family: "plusJakara_regular" !important;
}

.react-datepicker__month-container {
  width: 100% !important ;
  overflow-x: auto !important;
}
.tab_ {
  padding: 0.4rem 2rem;
  border-radius: 30px;
  min-width: 11.4rem !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 1px solid var(--secondary) !important;
}

.react-datepicker {
  background-color: transparent !important;
  border: none !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: none !important;
  color: #000 !important;
}

/* .react-datepicker__current-month {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000 !important;
  } */

.react-datepicker__navigation {
  color: #000 !important;
}

.react-datepicker__day-name {
  text-align: center !important;
  border-radius: 10px !important;
  border: 1px solid #262626 !important;
  padding: 0.7rem 2rem;
  background-color: var(--primary) !important;
  color: white !important;
}

.react-datepicker__current-month {
  /* padding: 0.9rem !important; */
  color: #000 !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.react-datepicker__day {
  text-align: center !important;
  border-radius: 10px !important;
  border: 1px solid var(--primary) !important;
  font-family: "plusJakara_medium" !important;
  padding: 0.7rem 2rem;
  background-color: var(--light) !important;
  color: var(--primary) !important;
}

@media (max-width: 620px) {
  .react-datepicker__day {
    padding: 0.5rem 1.5rem;
  }
  .react-datepicker__day-name {
    padding: 0.5rem 1.5rem;
  }
}
@media (max-width: 480px) {
  .react-datepicker__day {
    padding: 0.4rem 1.2rem;
  }
  .react-datepicker__day-name {
    padding: 0.4rem 1.2rem;
  }
}

.react-datepicker__day--selected {
  border: 2px solid var(--primary) !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-flex !important;
  justify-content: center !important;
}

.react-datepicker__day--disabled {
  pointer-events: none;
  opacity: 0.3;
  background-color: #d3d3d3;
}

.react-datepicker__day--highlighted {
  background-color: var(--primary) !important;
  color: white !important;
  border: 1px solid var(--primary) !important;
}

.rbc-toolbar .rbc-toolbar-label {
  width: fit-content !important;
  color: var(--primary) !important;
  position: absolute !important;
  top: 95px !important;
  font-family: "plusJakara_semibold" !important;
}

.rbc-btn-group {
  display: none !important;
}

.rbc-time-content > .rbc-time-gutter {
  display: none !important;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: transparent !important;
  font-family: "plusJakara_regular" !important;
}

.rbc-show-more {
  font-size: 14px !important;
  font-family: "plusJakara_semibold" !important;
  color: var(--primary) !important;
}
/* Accordion styling */
.accordian .accordion-button:not(.collapsed) {
  background-color: #18173c;
  color: white;
}

.accordian .accordion-button:not(.collapsed)::after {
  content: "";
  background-image: url("../assets/svg/Vector.svg");
}

.accordian .accordion-button {
  background-color: #f3f3f3;
  padding: 20px 20px;
}

@media (max-width: 768px) {
  .accordian .accordion-button {
    background-color: #f3f3f3;
    padding: 20px 10px;
  }
}

.accordian .accordion-button::after {
  content: "";
  background-image: url("../assets/svg/vector2.svg");
}

.accordian .accordion-button:focus {
  box-shadow: none;
  border-bottom: #acb5bb;
}

/* Ensure black color for the answer content when opened */
.accordian .accordion-body {
  color: black !important;
}

.rbc-label {
  padding: 0px !important;
}

.rmdp-arrow {
  border: solid var(--secondary2) !important;
  border-width: 0 2px 2px 0 !important;
  width: 10px !important;
  margin-top: 0px !important;
  height: 10px !important;
}

.rmdp-calendar {
  width: 420px !important;
  padding: 10px !important;
  height: 400px !important;
}

.rmdp-day-picker {
  display: inline !important;
}

.rmdp-week-day {
  font-size: 15px !important;
  color: var(--primary) !important;
  font-family: "plusJakara_semibold" !important;
}

.rmdp-header-values {
  font-size: 18px !important;
  color: var(--primary) !important;
  font-family: "plusJakara_semibold" !important;
}

.rmdp-header-values span {
  font-family: "plusJakara_semibold" !important;
}

.rmdp-day span {
  font-size: 15px !important;
  border-radius: 12px !important;
  font-family: "plusJakara_medium" !important;
  height: 35px !important;
  width: 35px !important;
  transition: background-color 0.5s ease, color 0.5s ease; /* Smooth transition */
}

@media (max-width: 768px) {
  .rmdp-calendar {
    width: 300px !important;
    padding: 2px !important;
    width: 100%;
    height: 340px !important;
  }

  .rmdp-arrow-container {
    height: 20px !important;
    width: 20px !important;
    border-radius: 12px !important;
    transition: background-color 0.5s ease, color 0.5s ease; /* Smooth transition */
  }
}

.rmdp-arrow-container:hover {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid var(--white) !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-day.rmdp-today span {
  background-color: transparent !important;
  color: var(--primary) !important;
}

.rmdp-arrow-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 12px !important;
  transition: background-color 0.5s ease, color 0.5s ease; /* Smooth transition */
}

.rmdp-day span:hover {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

.rmdp-day span:active {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

.rmdp-shadow {
  box-shadow: none !important;
  border: 0.5px solid #d3d3d3 !important;
}

.rmdp-week {
  margin-bottom: 10px !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

.react-datepicker__navigation-icon::before {
  top: 10px !important;
  height: 12px !important;
  border-color: var(--primary) !important;
  width: 12px !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-switch.ant-switch-checked {
  background-color: var(--primary) !important;
}
/* PaymentCheckCheckout.css */

.payment-form {
  max-width: 450px;
  margin: 0 auto;
  padding: 12px;
  border-radius: 8px;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.card-element-container {
  padding: 12px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.payment-button:hover {
  background-color: #0056b3;
}

.card-element-container .Button {
  display: none !important;
}
.card-element-container .Button,
.CollapsibleContainer {
  display: none !important;
}
.card-element-container
  .state-pay-visible.previous-state-hidden
  .ButtonContainer--pay,
.state-pay-visible.previous-state-use-visible .ButtonContainer--pay,
.state-save-visible.previous-state-hidden .ButtonContainer--save,
.state-save-visible.previous-state-pay-visible .ButtonContainer--save {
  display: none !important;
}

.card-element-container .ButtonContainer .ButtonContainer--pay {
  display: none !important;
  opacity: 0 !important;
}

.card-element-container .state-pay-visible .ButtonContainer--pay {
  display: none !important;
  opacity: 0 !important;
}

.ElementsApp .InputElement {
  background-color: white !important;
}

.image_header {
  width: 450px;
  height: 450px;
  /* max-width: 500px;
  max-height: 500px; */
}
.image_header2 {
  background-image: url(../assets/png/cleaningheader2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 100vw; */
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  /* height: 38rem; */
  background-position: center;
}

.image_header3 {
  background-image: url(../assets/png/cleaningheader3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 100vw; */
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  /* height: 38rem; */
  background-position: top center;
}

@media (max-width: 768px) {
  .image_header {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 400px;
  }
}

/* datatable pagination */

.pagination .page-item {
  width: 32px !important;
  height: 32px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.pagination .page-item a {
  font-family: "plusJakara_semibold";
  border: transparent;
  background-color: transparent;
  color: #111827;
}

.pagination li.page-item.active {
  border: transparent;
  background: #f8f8f8 !important;
  color: #111827 !important;
}

.pagination .prev-item {
  margin-right: 20px;
  border: 1px solid gainsboro;
}

.page-item.prev-item.disabled {
  border: 1px solid gainsboro;
  background-color: gainsboro !important;
}

.page-item.next-item.disabled {
  border: 1px solid gainsboro;
  background-color: gainsboro !important;
}

.pagination .prev-item a {
  border: transparent;
  background-color: transparent;
}

.pagination .next-item {
  margin-left: 20px;
  border: 1px solid gainsboro;
}

.page-item .next-item .disabled {
  border: 1px solid gainsboro;
  background-color: gainsboro !important;
}

.pagination .next-item a {
  border: transparent;
  background-color: transparent;
}

@media (max-width: 768px) {
  .pagination .page-item {
    width: 26px !important;
    height: 26px !important;
    font-size: 14px;
  }

  .pagination .prev-item {
    margin-right: 5px;
  }

  .pagination .next-item {
    margin-left: 5px;
  }
}

.mirrored-bg {
  background: rgba(
    255,
    255,
    255,
    0.1
  ); /* Light transparency for the background */
  backdrop-filter: blur(10px); /* Apply the mirror-like blur effect */
  -webkit-backdrop-filter: blur(10px); /* Ensure compatibility with Safari */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
}
