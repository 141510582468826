.items_swiper .swiper {
  padding-top: 4rem !important;
  padding-bottom: 1rem !important;
}

.items_swiper .swiper-button-next {
  bottom: auto !important;
  z-index: 9999 !important;
  top: 30px !important;
  width: 2rem;
  height: 2rem;
  right: 0 !important;
}

.items_swiper .swiper-button-prev {
  bottom: auto !important;
  top: 30px !important;
  z-index: 9999 !important;
  width: 2rem;
  left: auto;
  right: 3.2rem;
  height: 2rem;
}

.items_swiper .swiper-pagination {
  text-align: center !important;
}
.items_swiper
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}
.items_swiper .swiper-pagination-bullet {
  height: 15px;
  background-color: #d9d9d9 !important;
  width: 15px;
  border-radius: 50%;
}
.items_swiper .swiper-pagination-bullet-active {
  background-color: #18173c !important;
  /* width: 30px; */
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.items_swiper .swiper-button-next::after {
  content: "" !important;
  background-image: url("../assets/png/rightarrow.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.items_swiper .swiper-button-prev::after {
  content: "" !important;
  background-image: url("../assets/png/leftarrow.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  display: inline-block;
}

@media (max-width: 768px) {
  .items_swiper .swiper-button-next,
  .items_swiper .swiper-button-prev {
    width: 1.5rem;
    height: 1.5rem;
  }
  .items_swiper .swiper-button-prev {
    right: 2.5rem;
  }
  .items_swiper .swiper-pagination-bullet {
    height: 12px !important;
    width: 12px !important;
  }
  .items_swiper
    .swiper-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 3px;
  }
}

/* testimonials swiper */

.testimonial_swiper .swiper {
  padding-bottom: 5rem;
}

.testimonial_swiper .swiper-pagination {
  text-align: center !important;
}
.testimonial_swiper
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}
.testimonial_swiper .swiper-pagination-bullet {
  height: 20px;
  background-color: #d9d9d9 !important;
  width: 20px;
  border-radius: 50%;
}
.testimonial_swiper .swiper-pagination-bullet-active {
  background-color: #18173c !important;
  /* width: 30px; */
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

/* home swiper */

.home_swiper .swiper {
  padding: 0px !important;
}

.home_swiper .swiper-pagination {
  text-align: center !important;
}
.home_swiper
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}

.home_swiper .swiper-button-next {
  width: 1.5rem;
  height: 1.5rem;
  right: 10px;
  top: 60% !important;
  bottom: auto !important;
  transition: transform 0.5s ease;
}

.home_swiper .swiper-button-prev {
  width: 1.5rem;
  left: 10px;
  height: 1.5rem;
  top: 60% !important;
  bottom: auto !important;
  transition: transform 0.5s ease;
}

.home_swiper .swiper-button-next,
.home_swiper .swiper-button-prev {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.5s ease;
}

/* Show the buttons when hovering over the swiper container */
.home_swiper:hover .swiper-button-next,
.home_swiper:hover .swiper-button-prev {
  opacity: 1;
  visibility: visible;
}

.home_swiper .swiper-button-next {
  right: 10px;
}

.home_swiper .swiper-button-prev {
  left: 10px;
}
.home_swiper:hover .swiper-button-next {
  transform: translateX(-10px);
}

.home_swiper:hover .swiper-button-prev {
  transform: translateX(10px);
}

.home_swiper .swiper-button-next::after {
  content: "" !important;
  background-image: url("../assets/png/chevron-right.png");
  background-size: cover;
  background-color: #fff !important;
  width: 100%;
  border-radius: 50% !important;
  height: 100%;
  display: inline-block;
}

.home_swiper .swiper-button-prev::after {
  content: "" !important;
  background-image: url("../assets/png/chevron-left.png");
  background-size: cover;
  background-color: #fff !important;
  width: 100%;
  border-radius: 50% !important;
  height: 100%;
  display: inline-block;
}

/* detail swiper */

.detail_page_swiper .swiper-button-next {
  right: 10px !important;
  width: 30px !important;
  height: 30px !important;
}

.detail_page_swiper .swiper-button-prev {
  left: 10px !important;
  width: 30px !important;
  height: 30px !important;
}

.detail_page_swiper .swiper-button-next::after {
  content: "" !important;
  background-image: url("../assets/png/arrownext.png");
  background-size: cover;
  width: 100%;
  height: 100%;
}

.detail_page_swiper .swiper-button-prev::after {
  content: "" !important;
  background-image: url("../assets/png/arrowprev.png");
  background-size: cover;
  width: 100%;
  height: 100%;
}

.home_swiper_detail .swiper-pagination {
  text-align: center !important;
}
.home_swiper_detail
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}
.home_swiper_detail .swiper {
  padding-top: 0px !important;
}
.home_swiper_detail .swiper-button-next {
  width: 2rem;
  height: 2rem;
  right: 10px;
  margin-top: 0px !important;
  top: 50% !important;
  bottom: auto !important;
  /* transform: translateY(-50%); */
  transition: transform 0.5s ease;
}

@media (max-width: 768px) {
  .home_swiper_detail .swiper-button-next,
  .home_swiper_detail .swiper-button-prev {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.home_swiper_detail .swiper-button-prev {
  width: 2rem;
  top: 50% !important;
  margin-top: 0px !important;
  bottom: auto !important;
  left: 10px;
  height: 2rem;
  transition: transform 0.5s ease;
}

.home_swiper_detail .swiper-button-next,
.home_swiper_detail .swiper-button-prev {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.5s ease;
}

/* Show the buttons when hovering over the swiper container */
.home_swiper_detail:hover .swiper-button-next,
.home_swiper_detail:hover .swiper-button-prev {
  opacity: 1;
  visibility: visible;
}

.home_swiper_detail .swiper-button-next {
  right: 40px;
}

.home_swiper_detail .swiper-button-prev {
  left: 40px;
}
.home_swiper_detail:hover .swiper-button-next {
  transform: translateX(-40px);
  transition: transform 0.3s ease;
}

.home_swiper_detail:hover .swiper-button-prev {
  transform: translateX(40px);
  transition: transform 0.3s ease;
}
@media (max-width: 768px) {
  .home_swiper_detail .swiper-button-next {
    right: 10px;
  }
  .home_swiper_detail:hover .swiper-button-next {
    transform: translateX(-20px);
    transition: transform 0.3s ease;
  }
  .home_swiper_detail .swiper-button-prev {
    left: 10px;
  }
  .home_swiper_detail:hover .swiper-button-prev {
    transform: translateX(20px);
    transition: transform 0.3s ease;
  }
}

.home_swiper_detail .swiper-button-next:hover,
.home_swiper_detail .swiper-button-prev:hover {
  scale: 1.1;
  transition: scale 0.3s ease;
}

.home_swiper_detail .swiper-button-next::after {
  content: "" !important;
  background-image: url("../assets/png/arrownext.png");
  background-size: cover;
  /* background-color: #fff !important; */
  width: 100%;
  border-radius: 50% !important;
  height: 100%;
  display: inline-block;
}

.home_swiper_detail .swiper-button-prev::after {
  content: "" !important;
  background-image: url("../assets/png/arrowprev.png");
  background-size: cover;
  /* background-color: #fff !important; */
  width: 100%;
  border-radius: 50% !important;
  height: 100%;
  display: inline-block;
}

.home_swiper_detail .swiper-backface-hidden .swiper-slide {
  margin-right: 0px !important;
}
