@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

main {
    flex-grow: 1; 
}